import api from './base';

export default{
  async getClassList(payload) {
    const result = await api().get('/api/Class/GetByCampus/' + payload);
    return result.data;
  },
  async deleteClass(payload) {
    const result = await api().delete('/api/Class/' + payload);
    return result.data;
  },
  async createClass(payload) {
    const result = await api().post('/api/Class', payload);
    return result.data;
  },
}