<template>
  <div class="container-sm container-md" id="class">
    <div class="card">
      <a-tabs defaultActiveKey="1">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="bars" />List
          </span>
          <class-list :schoolList="schoolList"></class-list>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="plus" />Create
          </span>
          <create-class :schoolList="schoolList"></create-class>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import ClassList from '@/components/Class/List.vue';
import CreateClass from '@/components/Class/Create.vue';
import schoolService from '@/services/school';

export default {
  components: {
    ClassList,
    CreateClass
  },
  data(){
    return {
      schoolList: [],
    }
  },
  created() {
    this.setSchoolList();
    this.$store.commit('setActiveNavText', '3');
  },
  methods: {
    async setSchoolList() {
      const result = await schoolService.getSchoolList();
      this.schoolList = result;
    },
  }
}
</script>
<style scoped>
.card {
  box-shadow: 0 0px 3px rgba(0,0,0,0.12), 0 0px 2px rgba(0,0,0,0.24);
  margin-top: 20px;
  padding: 30px;
}
</style>