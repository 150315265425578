import api from './base';

export default{
  async getCampusList(payload) {
    const result = await api().get('/api/Campus/getBySchool/' + payload);
    return result.data;
  },
  async deleteCampus(payload) {
    const result = await api().delete('/api/Campus/' + payload);
    return result.data;
  },
  async createCampus(payload) {
    const result = await api().post('/api/Campus', payload);
    return result.data;
  },
}